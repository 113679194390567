body {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
}

.yellotail {
    font-family: 'Yellowtail', cursive;
}

nav { 
    border-bottom: 3px solid #6c6c6c;
}
.navbar {
    justify-content: center;
}
.nav {
  border-bottom: 1px solid #e6e6e6;
}
.nav .nav-link {
  color: #FFA500;
  font-weight: bold;
  font-size: 15px;
}

/*////////////////////////////////////
            MASONRY CSS
/////////////////////////////////////*/

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
 
/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.my-masonry-grid .content { 
    padding:15px;
    background: white;
    border: 1px solid #dbdbdb;
}
.my-masonry-grid .content .title { 
    border-bottom: 1px solid #ececec;
    font-size: 23px;
    margin-bottom: 12px;
    padding-bottom: 10px;
    font-weight: 500;
}

.my-masonry-grid .content .excerpt {
    font-weight: 100;
}

.my-masonry-grid .author {
    padding: 5px 10px;
    font-size: 12px;
    background: #dcdcdc;
    font-weight: 300;
}

@media (max-width: 800px) {
    .my-masonry-grid {
      margin-left: -15px; /* gutter size offset */
    }
    .my-masonry-grid_column {
      padding-left: 15px; /* gutter size offset */
    }
    .my-masonry-grid_column > div {
      margin-bottom: 15px; /* space between items */
    }
  }


/*////////////////////////////////////
            NEWSLETTER
/////////////////////////////////////*/

.newsletter_container {
  margin-top: 20px;
  border-top: 5px solid #3c3c3c;
  padding-top: 20px;
}

.newsletter_container h1 {
    text-align: center;
    font-family: 'Yellowtail', cursive;
}

.newsletter_container .form {
    width: 500px;
    margin: 0 auto;
}

/*////////////////////////////////////
            ARTICLE
/////////////////////////////////////*/

.article_container h1 {
font-size: 58px;
font-weight: 300;
}

.article_container .image{
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100%;
  height: 500px;
}
.article_container .author {
  margin-top: 10px;
  font-style: italic;
  font-weight: 300;
}

.localizacao {
  display: inline-grid;
  margin-right: auto;
  width: 70px;
}

.nome-campo{
  margin-bottom: 5px;
}

.horizontal-divider{
  height:2px;
  border-width:0;
  color:gray;
  background-color:gray
}

button .btn-sm .button-mapa{
  font-size: 11px;
}

.equipa-casa{
  padding-right: 20px;
}

.equipa-fora{
  padding-left: 20px;
}

.resultado,
.dia,
.hora,
.golos-lg,
.nome-equipa,
.grupo-jogo,
.estado-jogo,
.simbolo-equipa-lg,
.simbolo-equipa{
  display: flex;
  align-items: center;
  justify-content: center;
}

.nome-equipa,
.nome-campo{
  text-align: center;
}

.equipa-casa,
.equipa-fora,
.dia-hora{
  align-items: center;
  justify-content: center;
}

.equipa-casa,
.equipa-fora{
  flex-grow: 1;
   flex-basis: 0;
}


.resultado,
.dia{
  font-size: 15px;
  font-weight: 900;
}

.golos-lg{
  font-size: 18px;
  font-weight: 900;
}

.accoes_jogos{
  margin-left: auto;
}

.simbolo-equipa img {
  max-height: 50px;
}

.simbolo-equipa-lg img{
  height: 75px;
  margin-bottom: 5px;
}

.titulo-tabela{
  font-size: 17px;
  font-weight: 700;
}

@media only screen and (max-width: 600px)  {
  .tabela{
    width: 100%;
  }

  .titulo-pombalcup{
    max-width: 200px;
  }
}

@media only screen and (min-width: 600px)  {
  .tabela{
    width: 80%;
  }

  .titulo-pombalcup{
    max-width: 500px;
  }
}

.card-header {
    font-size: 1rem;
    font-family: tahoma;
    font-weight: bold;
  }

  .text-big-total{
    font-size: 1.5rem;
    font-family: tahoma;
    font-weight: bold; 
  }

  .margin-left-cm {
    margin-left: 10px;
  }

  .cursor-pointer { cursor: pointer; }

  .ul-fora {text-align: right;}

  .bt-eliminar-golo {
    margin: 0.2rem;
    padding: 0.2rem;
  }

  .mr-card-footer{
  margin-right: 0.2rem;
  }

.fa-blank
{
visibility:hidden !important;
}

.fill-grid {
  block-size: 100%;
}

.root-grid {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  block-size: 100vh;
  padding: 8px;
  contain: inline-size;
}

.fill-grid {
  block-size: 100%;
}

.s8wc6fl7-0-0-beta-44{
  inset-block-start: var(--rdg-summary-row-top);
  inset-block-end: var(--rdg-summary-row-bottom);
}

div.rdg-row.rdg-row-even {
  background-color: aliceblue !important;
}